import { Link } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import newLogo from "../../../assets/icons/Mazaj-b2b.svg";
import styles from "./style.module.scss";
import { DashboardRouteUtil } from "../../../core/routes/dashboard.route";
import { SidebarType } from "../../../core/types/sidebar.type";
import { useEffect, useState } from "react";
import React from "react";
import { STORAGE_CONSTRAINT } from "../../../core/enums/storage";
import Storage from "../../../core/helpers/storage";

export type DashboardWrapperProps = {
  children: JSX.Element;
  withoutHeader?: boolean;
  withOverlay?: boolean;
  fullOverlay?: boolean;
  title?: string;
  additionalMenu?: JSX.Element;
};

export const AppWrapper = ({ children }: DashboardWrapperProps) => {
  const [userData, SetUserData] = useState<any>(STORAGE_CONSTRAINT.PROFILE_DATA);
  const { t } = useTranslation();
  const [activePage, setActivePage] = useState("");

  useEffect(() => {
    const pathArray = window.location.pathname.split("/");
    if (pathArray[2]) {
      setActivePage(pathArray[2]);
    } else {
      setActivePage(pathArray[1]);
    }
    SetUserData(Storage.get(STORAGE_CONSTRAINT.PROFILE_DATA));
  }, []);

  const list: SidebarType[] = [
    {
      label: "dashboard",
      link: DashboardRouteUtil.getDashboardPath(),
      icon: require(`../../../assets/icons/dashboard.svg`).default,
      isActive: activePage === "dashboard",
      module: "dashboard",
    },
    {
      label: "brand",
      link: DashboardRouteUtil.getBrandPath(),
      icon: require(`../../../assets/icons/sellers.svg`).default,
      isActive: activePage === "suppliers",
      module: "suppliers",
    },
    {
      label: "branches",
      link: DashboardRouteUtil.getBranchesPath(),
      icon: require(`../../../assets/icons/sellers.svg`).default,
      isActive: activePage === "suppliers",
      module: "suppliers",
    },
    {
      label: "orders",
      link: DashboardRouteUtil.getOrdersListPath(),
      icon: require(`../../../assets/icons/orders.svg`).default,
      isActive: activePage === "orders",
      module: "orders",
    },
  ];

  return (
    <>
      <Container className="d-block">
        <div className={"overlay-container"} />
      </Container>
      <div className={`${styles.wrapper} d-md-flex`}>
        <div className={`d-sm-block app-side-menu ${styles.sideBar}`}>
          <Container>
            <Row className="align-items-center justify-content-center">
              <Col className="m-2 text-center">
                <img
                  src={userData?.logo_image?.path ?? newLogo}
                  alt="brand_Logo"
                  width={100}
                  className="large-mazaj-logo"
                />
                <img
                  src={userData?.logo_image?.path ?? newLogo}
                  alt="brand_Logo"
                  width="50"
                  height="50"
                  className="small-mazaj-logo"
                />
              </Col>
            </Row>
            <Row className="align-items-center justify-content-center">
              {list.map((item: SidebarType) => (
                <Col xs="12">
                  <Link to={item.link} className="text-decoration-none">
                    <Button
                      className={`${styles.btnFont} ${
                        item.isActive ? "text-primary bg-primary-light" : ""
                      } w-100 py-2 d-flex align-items-center btn-primary-light mb-3`}
                      variant=""
                    >
                      <img src={item.icon} alt={item.label} />
                      <span className="mx-3 menu-item-label">
                        {t(`wrappers.dashboard.${item.label}`)}
                      </span>
                    </Button>
                  </Link>
                </Col>
              ))}
            </Row>
            <div className={"overlay-container"} />
          </Container>
        </div>
        {children}
      </div>
    </>
  );
};

import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { withAuth } from "./core/helpers/withAuth";
import { withPermission } from "./core/helpers/withPermission";
import { withSuspense } from "./core/helpers/withSuspense";
import { DashboardRouteUtil } from "./core/routes/dashboard.route";
import { authRoutes, branchesRoute, brandRoute } from "./routes/index.routing";

const OrdersPage = React.lazy(() => import("./pages/dashboard/order/list"));

const OrderEditPage = React.lazy(() => import("./pages/dashboard/order/edit"));

const DashboardShowPage = React.lazy(() => import("./pages/dashboard/dashboard/show"));

function AppRouting() {
  const dashboardRoutes: JSX.Element[] = [
    <Route
      exact
      key="dashboard"
      path={DashboardRouteUtil.getDashboardPath()}
      component={() => withAuth(withPermission("dashboard_view", withSuspense(DashboardShowPage)))}
    />,
    <Route
      exact
      key="orders"
      path={DashboardRouteUtil.getOrdersListPath()}
      component={() => withAuth(withSuspense(OrdersPage))}
    />,
    <Route
      exact
      key="view-order"
      path={DashboardRouteUtil.getOrderEditPath()}
      component={() => withAuth(withSuspense(OrderEditPage))}
    />,
  ];

  const commonRoutes = [
    <Redirect path="*" to={DashboardRouteUtil.getDashboardPath()} exact key="redirect" />,
  ];

  const finalRoutes = [
    ...authRoutes,
    ...dashboardRoutes,
    ...brandRoute,
    ...branchesRoute,
    ...commonRoutes,
  ];

  return (
    <React.Suspense fallback={<></>}>
      <Switch>{finalRoutes}</Switch>
    </React.Suspense>
  );
}

export default AppRouting;

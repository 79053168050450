import React from "react";
import { Route } from "react-router";
import { withAuth } from "../core/helpers/withAuth";
import { DashboardRouteUtil } from "../core/routes/dashboard.route";
import { withSuspense } from "../core/helpers/withSuspense";

const Branches = React.lazy(() => import("../pages/dashboard/branches/list"));

const SupplierBranchCreateUpdate = React.lazy(
  () => import("../pages/dashboard/branches/create-edit-branch")
);

export const branchesRoute: JSX.Element[] = [
  <Route
    exact
    key="branches"
    path={DashboardRouteUtil.getBranchesPath()}
    component={() => withAuth(withSuspense(Branches))}
  />,

  <Route
    exact
    key="edit-branch"
    path={DashboardRouteUtil.getEditBranchesPath()}
    component={() => withAuth(withSuspense(SupplierBranchCreateUpdate))}
  />,
];

import React from "react";
import { Route } from "react-router";
import { withAuth } from "../core/helpers/withAuth";
import { DashboardRouteUtil } from "../core/routes/dashboard.route";
import { withSuspense } from "../core/helpers/withSuspense";

const brandPage = React.lazy(() => import("../pages/dashboard/brand/edit"));

const SectionsPage = React.lazy(() => import("../pages/dashboard/brand/section/list"));

const WorkingHoursPage = React.lazy(() => import("../pages/dashboard/brand/working-hours"));

const EditItemPage = React.lazy(() => import("../pages/dashboard/brand/items/edit"));

const CreateItemPage = React.lazy(() => import("../pages/dashboard/brand/items/create"));

const ItemsListPage = React.lazy(() => import("../pages/dashboard/brand/items/list"));

const CustomersListPage = React.lazy(() => import("../pages/dashboard/brand/customers/list"));

export const brandRoute: JSX.Element[] = [
  <Route
    exact
    key="brand"
    path={DashboardRouteUtil.getBrandPath()}
    component={() => withAuth(withSuspense(brandPage))}
  />,

  <Route
    exact
    key="brand-working-hours"
    path={DashboardRouteUtil.getBrandWorkingHourPath()}
    component={() => withAuth(withSuspense(WorkingHoursPage))}
  />,
  <Route
    exact
    key="brand-sections"
    path={DashboardRouteUtil.getBrandSectionUrl()}
    component={() => withAuth(withSuspense(SectionsPage))}
  />,
  <Route
    exact
    key="brand-items-edit"
    path={DashboardRouteUtil.getBrandItemsEditPath()}
    component={() => withAuth(withSuspense(EditItemPage))}
  />,
  <Route
    exact
    key="brand-items-create"
    path={DashboardRouteUtil.getBrandItemsCreatePath()}
    component={() => withAuth(withSuspense(CreateItemPage))}
  />,

  <Route
    exact
    key="brand-items-list"
    path={DashboardRouteUtil.getBrandItemsListPath()}
    component={() => withAuth(withSuspense(ItemsListPage))}
  />,
  <Route
    exact
    key="brand-customers-list"
    path={DashboardRouteUtil.getBrandCustomersListPath()}
    component={() => withAuth(withSuspense(CustomersListPage))}
  />,
  <Route
    exact
    key="brand-customers-details"
    path={DashboardRouteUtil.getCustomerDetailsPath()}
    component={() => withAuth(withSuspense(CustomersListPage))}
  />,
];

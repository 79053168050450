import React from "react";
import { Route } from "react-router-dom";
import { withSuspense } from "../core/helpers/withSuspense";
import { withAuth } from "../core/helpers/withAuth";
import { DashboardRouteUtil } from "../core/routes/dashboard.route";

const ForgetPasswordPage = React.lazy(() => import("../pages/login/forget-password"));

const UserConfirmPasswordPage = React.lazy(() => import("../pages/login/confirm-password"));

const SuccessEmailPage = React.lazy(() => import("../pages/login/success-email"));

const UserLoginPage = React.lazy(() => import("../pages/login/user-login"));

const AccountSettings = React.lazy(() => import("../pages/dashboard/account-settings"));

export const authRoutes: JSX.Element[] = [
  <Route
    exact
    key="login"
    path={DashboardRouteUtil.getLoginPath()}
    component={() => withSuspense(UserLoginPage)}
  />,
  <Route
    exact
    key="forget-password"
    path={DashboardRouteUtil.getForgetPasswordPath()}
    component={() => withSuspense(ForgetPasswordPage)}
  />,
  <Route
    exact
    key="success-email"
    path={DashboardRouteUtil.getSuccessSendPath()}
    component={() => withSuspense(SuccessEmailPage)}
  />,
  <Route
    exact
    key="confirm-password"
    path={DashboardRouteUtil.getConfirmPasswordPath()}
    component={() => withSuspense(UserConfirmPasswordPage)}
  />,
  <Route
    exact
    key="account-settings"
    path={DashboardRouteUtil.getAccountSettingsPath()}
    component={() => withAuth(withSuspense(AccountSettings))}
  />,
];

import { HttpMethods } from "../enums/http-methods.enum";
import { makeRequest, baseURL } from "./http.service";

export const getUserProfile = async () => {
  return await makeRequest({
    url: `${baseURL}/profile`,
    method: HttpMethods.GET,
  });
};

export const updateUserProfile = async (data: any) => {
  return await makeRequest({
    url: `${baseURL}/profile`,
    method: HttpMethods.PUT,
    data,
  });
};

export const resetUserPassword = async (data: any) => {
  return await makeRequest({
    url: `${baseURL}/profile/password`,
    method: HttpMethods.PUT,
    data,
  });
};

const dashboardPrefix = "/dashboard";
export class DashboardRouteUtil {
  public static getLoginPath(): string {
    return "/";
  }

  public static getForgetPasswordPath(): string {
    return "/forget-password";
  }

  public static getSuccessSendPath(): string {
    return "/success-send";
  }

  public static getConfirmPasswordPath(): string {
    return "/password/reset";
  }

  public static getDashboardPath(): string {
    return `${dashboardPrefix}`;
  }

  public static getAccountSettingsPath(): string {
    return `${dashboardPrefix}/account-settings`;
  }

  public static getOrdersListPath(): string {
    return `${dashboardPrefix}/orders`;
  }

  public static getOrderEditPath(): string {
    return `${dashboardPrefix}/orders/:order_id/edit`;
  }

  public static getOrderEditUrl(order_id: string): string {
    return `${dashboardPrefix}/orders/${order_id}/edit`;
  }

  // start brand
  public static getBrandPath(): string {
    return `${dashboardPrefix}/brand`;
  }

  public static getBrandWorkingHourPath(): string {
    return `${dashboardPrefix}/brand/working-hours`;
  }

  public static getBrandSectionUrl(): string {
    return `${dashboardPrefix}/brand/sections`;
  }

  public static getBrandItemsListPath(): string {
    return `${dashboardPrefix}/brand/items`;
  }

  public static getBrandCustomersListPath(): string {
    return `${dashboardPrefix}/brand/customers`;
  }

  public static getCustomerDetailsPath(customer_id?: string): string {
    return customer_id
      ? `${dashboardPrefix}/brand/customer/${customer_id}/edit`
      : `${dashboardPrefix}/brand/customer/:customer_id/edit`;
  }

  public static setEditCustomerDetailsPath(supplier_id): string {
    return `${dashboardPrefix}/suppliers/${supplier_id}/customers/assign/edit`;
  }

  public static getBrandItemsEditPath(itemId?: string): string {
    return itemId
      ? `${dashboardPrefix}/brand/item/${itemId}/edit`
      : `${dashboardPrefix}/brand/item/:itemId/edit`;
  }
  public static getBrandItemsCreatePath(): string {
    return `${dashboardPrefix}/brand/item/create`;
  }

  public static getBranchesPath(): string {
    return `${dashboardPrefix}/branches`;
  }

  public static getEditBranchesPath(branchId?: string): string {
    return branchId
      ? `${dashboardPrefix}/branches/${branchId}/edit`
      : `${dashboardPrefix}/branches/:branchId/edit`;
  }

  // seller balance
  public static getSellerBalancePath(): string {
    return `${dashboardPrefix}/seller-balance`;
  }
  // seller balance branches
  public static getSellerBalanceBranchesPath(): string {
    return `${dashboardPrefix}/seller-balance/branches`;
  }
}
